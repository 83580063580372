import React from "react";
import {
  makeStyles,
  Text,
  Input,
  shorthands,
  tokens,
  Label,
  useId,
  Checkbox,
  Button,
  ToggleButton,
  Textarea,
} from "@fluentui/react-components";
import Navigation from "./components.tsx/Navigation";
import { FluentProvider, webDarkTheme } from "@fluentui/react-components";
import {
  PersonRegular,
  Mail24Regular,
  Box24Filled,
  CheckmarkCircle24Filled,
  ArrowCircleLeft24Regular,
} from "@fluentui/react-icons";
import { Alert } from "@fluentui/react-components/unstable";
import useUserService from "./hooks/useUserService";

export const MODULES = [
  {
    id: "0",
    name: "Dimetrics Service Composer",
    description:
      "Entwickeln Sie für jeden geschäftlichen Bedarf,maßgeschneidert auf Ihre einzigartigen Herausforderungen, einfach und unkompliziert Anwendungen – ganz ohne Programmierkenntnisse.",
    enabled: true,
    selected: false,
  },
  {
    id: "1",
    name: "Dimetrics Recruiting",
    description:
      "Die kolloborative, schnell individualisierbare Recruiting Software von Dimetrics bringt Ihr Bewerbermanagement auf Touren.",
    enabled: true,
    selected: false,
  },
  {
    id: "2",
    name: "Dimetrics CRM",
    description: "Schon bald verfügbar!",
    enabled: false,
    selected: false,
  },
];

function App() {
  const beforeId = useId("content-before");
  const { state, setState, loading, setLoading, userCreated, setUserCreated } =
    useUserService();
  const styles = useStyles();
  const [selectedTap, setSelectedTap] = React.useState("tab1");
  const [registrationCompleted, setRegistrationCompleted] =
    React.useState(false);

  const [error, setError] = React.useState("");

  const toggleChecked = React.useCallback(
    (id: string) => {
      console.log(id);
      let m = state.selectedModules;
      m[+id].selected = !state.selectedModules.find((m) => m.id === id)
        .selected;

      setState(
        (e) =>
          (e = {
            ...e,
            selectedModules: m,
          })
      );
    },
    [state]
  );

  React.useEffect(() => {
    setRegistrationCompleted(false);
  }, [selectedTap]);

  const handleCreateUser = () => {
    setSelectedTap("tab2");
    console.log(userCreated);
    if (userCreated) return;
    setLoading(true);
    const user = {
      first_name: state.firstName,
      name: state.lastName,
      email: state.email,
      message_confirmed: state.messagesConfirmed,
      completed: false,
    };
    fetch(
      `https://dimetricsapimanagement.azure-api.net/websiteservice/78e_appregistrations`,
      {
        body: JSON.stringify(user),
        method: "POST",
      }
    )
      .then((e) => {
        setLoading(false);
        setUserCreated(true);
        e.json().then((result) => {
          localStorage.setItem("registration_id", result.identifier);
          setState((s) => (s = { ...s, identifier: result.identifier }));
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleOnConfirmRegistration = () => {
    if (state.identifier) {
      const user = {
        first_name: state.firstName,
        name: state.lastName,
        email: state.email,
        message_confirmed: state.messagesConfirmed,
        organization: state.organization,
        selected_modules: JSON.stringify(
          state.selectedModules
            .filter((m) => m.selected === true)
            .map((e) => e.name)
        ),
        notes: state.notes,
        completed: true,
      };
      fetch(
        `https://dimetricsapimanagement.azure-api.net/websiteservice/78e_appregistrations/${state.identifier}`,
        {
          body: JSON.stringify(user),
          method: "PUT",
        }
      )
        .then((e) => {
          setLoading(false);
          setUserCreated(true);
          e.json().then((result) => {
            setRegistrationCompleted(true);
          });
        })
        .catch((err) => {
          setLoading(false);
          setRegistrationCompleted(false);
        });
    }
  };
  return (
    <div id="wrapper">
      <div id="sidebar-wrapper">
        <FluentProvider theme={webDarkTheme}>
          <Navigation
            state={state}
            selectedTab={selectedTap}
            onChange={setSelectedTap}
          />
        </FluentProvider>
      </div>

      <div id="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            {selectedTap == "tab1" && (
              <>
                <div className="col-lg-12">
                  <Text block weight="medium" size={600}>
                    Erstelle einen{" "}
                    <span style={{ color: "#3D55E2" }}>
                      <b>Dimetrics</b>
                    </span>{" "}
                    Account <br /> und lege noch heute los!
                  </Text>
                  <br />
                  <br />
                  <Text>
                    Registriere dich jetzt! Du musst keine Zahlungsinformationen
                    angeben.
                  </Text>
                  <br />
                </div>

                <div className="col-12 mt-5">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col">
                          <Label required weight="semibold" htmlFor={beforeId}>
                            Vorname
                          </Label>
                          <br />
                          <Input
                            id={beforeId}
                            contentBefore={<PersonRegular />}
                            value={state.firstName}
                            className="w-100"
                            width={"100%"}
                            onChange={(_, data) =>
                              setState(
                                (e) => (e = { ...e, firstName: data.value })
                              )
                            }
                          />
                        </div>
                        <div className="col">
                          <Label required weight="semibold" htmlFor={beforeId}>
                            Nachname
                          </Label>
                          <br />
                          <Input
                            value={state.lastName}
                            className="w-100"
                            width={"100%"}
                            onChange={(_, data) =>
                              setState(
                                (e) => (e = { ...e, lastName: data.value })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="row">
                    <div className="col-lg-4">
                      <Label required weight="semibold" htmlFor={beforeId}>
                        Email
                      </Label>
                      <br />
                      <Input
                        style={{ width: "100%" }}
                        id={beforeId}
                        contentBefore={<Mail24Regular />}
                        placeholder="e.g. name@company.com"
                        value={state.email}
                        onChange={(_, data) =>
                          setState((e) => (e = { ...e, email: data.value }))
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <Checkbox
                    checked={state.messagesConfirmed}
                    required
                    label="Ich bin damit einverstanden Emails von Dimetrics zu erhalten."
                    onChange={(_, data) =>
                      setState(
                        (e) =>
                          (e = {
                            ...e,
                            messagesConfirmed: data.checked as boolean,
                          })
                      )
                    }
                  />
                </div>
                <div className="col-12 mt-4">
                  <Button
                    onClick={handleCreateUser}
                    disabled={
                      !state.firstName ||
                      !state.lastName ||
                      !state.email ||
                      !state.messagesConfirmed ||
                      loading
                    }
                    appearance="primary"
                  >
                    Weiter
                  </Button>
                </div>
                <div className="col-12 mt-4">
                  <div className="row">
                    <div className="col-lg-4">
                      <Text style={{ color: "GrayText" }} size={200}>
                        Durch die Anmeldung stimmen Sie unseren{" "}
                        <a
                          rel="noreferrer"
                          target={"_blank"}
                          href="https://dimetrics.io/terms-of-use/"
                        >
                          Nutzungsbedingungen und Datenschutzhinweisen
                        </a>{" "}
                        zu. Diese Seite ist durch reCAPTCHA geschützt und es
                        gelten die Datenschutzbestimmungen und
                        Nutzungsbedingungen von Google.
                      </Text>
                    </div>
                  </div>
                </div>
                {error && (
                  <Alert intent="error" action="Retry">
                    Der Benutzer existiert bereits. Bitte wende dich an{" "}
                    <a href="mailto:support@dimetrics.io">
                      support@dimetrics.io
                    </a>
                  </Alert>
                )}
              </>
            )}
            {selectedTap == "tab2" && (
              <>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <Text block weight="medium" size={600}>
                        Für welches{" "}
                        <span style={{ color: "#3D55E2" }}>
                          <b>Modul</b>
                        </span>{" "}
                        <br />
                        möchtest du dich registrieren?
                      </Text>
                      <br />
                      <br />
                      <Text>
                        Wähle ein Modul aus, das du auf der Plattform verwenden
                        möchtest. Du kannst später weitere Module hinzufügen.
                        <br />
                        <br />
                        Damit wir deine Anforderungen besser verstehen können,
                        teile uns bitte einige Details über dein Projekt oder
                        die Probleme mit, die du lösen möchtest. Hast du
                        spezifische Funktionsanforderungen, die du gerne
                        integrieren möchtest? Durch diese Informationen können
                        wir dir besser helfen und eine passende Lösung für dich
                        finden.
                      </Text>
                      <br />
                    </div>
                    <div className="col-xs-6 d-flex align-items-stretch flex-wrap mt-5">
                      {MODULES.map((module) => (
                        <ToggleButton
                          style={{
                            display: "initial",
                            marginTop: 10,
                            maxWidth: 200,
                            textAlign: "start",
                            alignSelf: "start",
                          }}
                          appearance="subtle"
                          icon={<Box24Filled />}
                          disabled={!module.enabled}
                          onClick={() => toggleChecked(module.id)}
                          checked={
                            state.selectedModules
                              .filter((m) => m.selected === true)
                              .find((m) => m.name == module.name) != null
                          }
                        >
                          <Text className="mb-2" weight="semibold" block>
                            {module.name}
                          </Text>

                          <Text block size={100}>
                            {module.description}
                          </Text>
                        </ToggleButton>
                      ))}
                    </div>
                    <div className="col-lg-4 mt-3">
                      <Label required weight="semibold" htmlFor={beforeId}>
                        Organisation
                      </Label>
                      <br />
                      <Input
                        style={{ width: "100%" }}
                        placeholder="e.g. MeineFirma"
                        value={state.organization}
                        onChange={(_, data) =>
                          setState(
                            (e) => (e = { ...e, organization: data.value })
                          )
                        }
                      />
                      <Text size={100} style={{ color: "GrayText" }}>
                        Kann später noch geändert werden.
                      </Text>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 mt-3">
                        <Label weight="semibold" htmlFor={beforeId}>
                          Anmerkungen (optional)
                        </Label>
                        <br />
                        <Textarea
                          placeholder="z.B. Ich möchte nur testen."
                          style={{ height: 100, width: "100%" }}
                          value={state.notes}
                          onChange={(_, data) =>
                            setState((e) => (e = { ...e, notes: data.value }))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <Button
                        onClick={() => setSelectedTap("tab1")}
                        appearance="secondary"
                      >
                        Zurück
                      </Button>
                      <Button
                        onClick={() => setSelectedTap("tab3")}
                        disabled={
                          state.selectedModules.filter((m) => m.selected)
                            .length <= 0 || !state.organization
                        }
                        appearance="primary"
                      >
                        Weiter
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedTap == "tab3" && (
              <>
                {!registrationCompleted && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <Text block weight="medium" size={600}>
                          Bitte überprüfe deine Eingaben erneut <br /> und
                          schließe die{" "}
                          <span style={{ color: "#3D55E2" }}>
                            <b>Registrierung</b>
                          </span>{" "}
                          ab.
                        </Text>
                        <br />
                        <br />

                        <Text block>Deine Eingaben:</Text>
                        <br />
                        <table className={styles.propsTable}>
                          <tbody>
                            <tr>
                              <td>Vor- und Nachname</td>
                              <td>
                                {state.firstName} {state.lastName}
                              </td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>{state.email}</td>
                            </tr>
                            <tr>
                              <td>Organisation</td>
                              <td>{state.organization}</td>
                            </tr>
                            <tr>
                              <td>Anmerkungen</td>
                              <td>{state.notes}</td>
                            </tr>
                            <tr>
                              <td>Ausgewählte Module</td>
                              <td>
                                <ul>
                                  {state.selectedModules
                                    .filter((m) => m.selected === true)
                                    .map((e) => (
                                      <li>{e.name}</li>
                                    ))}
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {registrationCompleted && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <Text block weight="medium" size={600}>
                          Vielen Dank für Dein Interesse! <br /> Die{" "}
                          <span style={{ color: "#3D55E2" }}>
                            <b>Registrierung</b>
                          </span>{" "}
                          wurde erfolgreich abgeschlossen.
                        </Text>
                        <br />
                        <br />
                        <Text block>
                          In Kürze wirst du eine Bestätigung an deine
                          E-Mail-Adresse <a href="#">{state.email}</a> erhalten.
                        </Text>
                        <br />
                        <Text block>
                          Falls du in den nächsten Minuten keine E-Mail von uns
                          erhältst, überprüfe bitte deine Angaben und schaue, ob
                          diese korrekt sind. Du hast jederzeit die Möglichkeit,
                          deine Angaben im entsprechenden Registrierungsschritt
                          zu ändern, aber bitte vergiss nicht, diese Änderungen
                          zu bestätigen.
                        </Text>
                        <br />
                        <Text block>
                          Sollte es dennoch Probleme bei der Registrierung
                          geben, wende dich gerne an unseren{" "}
                          <a href="mailto:support@dimetrics.io">Support</a>.
                        </Text>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-12 mt-5 d-flex justify-content-center flex-wrap">
                  {!registrationCompleted && (
                    <>
                      <Button
                        onClick={() => setSelectedTap("tab2")}
                        appearance="transparent"
                        className="d-inline-block"
                      >
                        Zurück
                      </Button>
                      <Button
                        icon={<CheckmarkCircle24Filled />}
                        appearance="primary"
                        size="large"
                        onClick={handleOnConfirmRegistration}
                      >
                        {state.completed
                          ? "Änderungen senden"
                          : "Registrierung abschließen"}
                      </Button>
                    </>
                  )}
                  {registrationCompleted && (
                    <Button
                      icon={<ArrowCircleLeft24Regular />}
                      appearance="primary"
                      size="large"
                      onClick={() => setSelectedTap("tab1")}
                    >
                      Daten ändern
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("50px", "20px"),
    rowGap: "20px",
  },
  panels: {
    ...shorthands.padding(0, "10px"),
    "& th": {
      textAlign: "left",
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
    "& td": {
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});
