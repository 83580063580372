import {
  TabList,
  Tab,
  Text,
  SelectTabData,
  SelectTabEvent,
} from "@fluentui/react-components";
import React from "react";
import logo from "../assets/images/logo-dimetrics-1-1-1.png";
import {
  Person24Regular,
  Globe24Regular,
  CheckmarkCircle24Regular,
} from "@fluentui/react-icons";
import { IUserRegistation } from "../types/user.type";

type NavProps = {
  onChange: (tap: any) => void;
  selectedTab: string;
  state: IUserRegistation;
};

const Navigation: React.FC<NavProps> = (props) => {
  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    props.onChange(data.value);
  };
  const [selectedTab, setSelectedTap] = React.useState(
    props.selectedTab || "tab1"
  );

  const [state, setState] = React.useState<IUserRegistation>();

  React.useEffect(() => {
    setSelectedTap(props.selectedTab);
    setState(props.state);
  }, [props.selectedTab, props.state]);
  return (
    <>
      <div className="d-flex align-items-end justify-content-center mt-5 mb-5">
        <img src={logo} style={{ maxWidth: 70 }} alt="Logo" />
      </div>
      <TabList
        appearance="subtle"
        defaultSelectedValue={selectedTab}
        selectedValue={selectedTab}
        size="large"
        vertical
        onTabSelect={onTabSelect}
      >
        <Tab
          id="tap1"
          style={{ color: "#fff" }}
          icon={<Person24Regular />}
          value="tab1"
        >
          Persönliche Inforamtionen
        </Tab>
        <Tab
          id="tap2"
          style={{ color: "#fff" }}
          icon={<Globe24Regular />}
          value="tab2"
          disabled={!state?.identifier}
        >
          Konfiguration der Plattform
        </Tab>
        <Tab
          id="tap3"
          style={{ color: "#fff" }}
          icon={<CheckmarkCircle24Regular />}
          value="tab3"
          disabled={!state?.identifier}
        >
          Abschließen
        </Tab>
      </TabList>
    </>
  );
};

export default Navigation;
