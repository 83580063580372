import React from "react";
import { MODULES } from "../App";
import { IUserRegistation } from "../types/user.type";

const useUserService = () => {
  const [loading, setLoading] = React.useState(false);
  const [userCreated, setUserCreated] = React.useState(false);
  const [state, setState] = React.useState<IUserRegistation>({
    firstName: "",
    lastName: "",
    email: "",
    messagesConfirmed: false,
    selectedModules: MODULES,
    organization: "",
    notes: "",
    completed: false,
  });
  React.useEffect(() => {
    const registration_id = localStorage.getItem("registration_id");
    if (registration_id) {
      setLoading(true);
      fetch(
        `https://dimetricsapimanagement.azure-api.net/websiteservice/78e_appregistrations/${registration_id}`,
        {
          method: "GET",
        }
      )
        .then((e) => {
          setLoading(false);

          e.json().then((result: any) => {
            if (e.ok) {
              setUserCreated(true);
            } else {
              setUserCreated(false);
            }
            setState({
              identifier: result.identifier,
              email: result.email,
              firstName: result.first_name,
              lastName: result.name,
              messagesConfirmed: result.message_confirmed,
              notes: result.notes || "",
              organization: result.organization || "",
              selectedModules: MODULES,
              completed: result.completed,
            });
          });
        })
        .catch((err) => {
          setLoading(false);
          setUserCreated(false);
        });
    }
  }, []);

  return { state, setState, loading, setLoading, userCreated, setUserCreated };
};

export default useUserService;
